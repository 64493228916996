@import "../../../styles/base/abstracts";

.hbs-cta-list-row {
  @include padding($grid-padding-mobile x);

  @include mq($bp-tablet) {
    @include padding($grid-padding x);
  }
}

.hbs-cta-list-row__wrapper {
  @include grid-parent;
  justify-content: space-between;

  &--media-left {
    flex-direction: row-reverse;
  }
}

.hbs-cta-list-row__content {
  display: flex;
  flex-direction: column;
  order: 1;
  width: 100%;

  .hbs-cta-list-row--asset & {
    @include grid-child;
  }

  @include mq($bp-tablet) {
    .hbs-cta-list-row--asset & {
      width: 50%;
    }
  }

  .hbs-global-align-center .hbs-cta-list-row--text-only & {
    flex-wrap: wrap;
  }

  .hbs-global-align-center .hbs-cta-list-row--asset-count-1 & {
    @include mq($bp-desktop) {
      width: 100%;
      order: 2;
    }
  }

  .hbs-global-align-center .hbs-cta-list-row--asset-count-2 & {
    @include mq($bp-desktop) {
      width: 100%;
      order: 2;
    }
  }

  .hbs-cta-list-row--text-only & {
    @include mq($bp-tablet) {
      flex-direction: row;
      gap: $col-1;
    }

    @include mq($bp-desktop) {
      gap: $col-2;
    }
  }
}

.hbs-cta-list-row__content-top {
  .hbs-cta-list-row--text-only & {
    @include grid-child;

    @include mq($bp-tablet) {
      width: $col-7;
    }

    @include mq($bp-tablet-xl) {
      width: $col-6;
    }
  }

  .hbs-global-align-center .hbs-cta-list-row--text-only & {
    @include mq($bp-desktop) {
      width: 100%;
    }
  }
}

.hbs-cta-list-row__title {
  @include h2;
  max-width: 12em;
}

.hbs-cta-list-row__content-bottom {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;

  .hbs-cta-list-row--text-only & {
    @include grid-child;

    @include mq($bp-tablet) {
      width: $col-8;
    }
  }
}

.hbs-cta-list-row__description {
  color: $c-text-light;
  max-width: 32em;
  padding-top: $spacing-sm;
}

.hbs-cta-list-row__cta-wrapper {
  padding-top: $spacing-xl;
}

.hbs-cta-list-row__cta-title {
  @include h5;
  margin-bottom: $spacing-md;
}

.hbs-cta-list-row__cta {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-xs;

  .hbs-cta-list-row-link--link {
    @include padding($spacing-sm x);
  }
}

// Media
.hbs-cta-list-row__media {
  align-items: flex-end;
  display: flex;
  flex-flow: row wrap;
  padding-bottom: $grid-padding-mobile * 2;

  .hbs-media-asset {
    @include grid-child;
  }

  @include mq($bp-tablet) {
    order: 1;
    padding-bottom: 0;
  }

  @include mq($bp-desktop) {
    margin-top: 0;
  }

  .hbs-global-align-center .hbs-cta-list-row--asset-count-1 & {
    @include mq($bp-desktop) {
      margin-left: auto;
    }
  }

  .hbs-global-align-center .hbs-cta-list-row--asset-count-2 & {
    .hbs-media-asset {
      @include mq($bp-desktop) {
        @include padding(x $grid-padding-mobile);
      }
    }

    @include mq($bp-desktop) {
      width: $col-9;
      margin-left: auto;
    }
  }
}

// Media Count
.hbs-cta-list-row__media {
  width: 100%;

  @include mq($bp-tablet) {
    width: 50%;
  }
}

.hbs-cta-list-row--asset-count-1 {
  .hbs-cta-list-row__media {
    @include mq($bp-tablet) {
      width: $col-8;
    }
  }
}

.hbs-cta-list-row--asset-count-2 {
  .hbs-cta-list-row__media {
    .hbs-media-asset {
      width: $col-8;
    }
  }
}

.hbs-cta-list-row__content,
.hbs-cta-list-row__media {
  @include margin($grid-padding-mobile x);

  @include mq($bp-tablet) {
    @include margin($grid-padding x);
  }
}
